import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { DeleteForeverTwoTone } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import { parseGraphqlErrors } from '../../../utils/FormikUtils';
import { Navbar } from '../../Navbar/styled/NavbarStyles';
import { HospitalForm } from './HospitalForm';
import Loading from '../../Common/Loading';
import { isEmpty } from '../../../utils/ObjectUtils';
import { GET_HOSPITALS, GET_HOSPITAL } from '../../../graphql/queries';
import { UPDATE_HOSPITAL } from '../../../graphql/mutations';
import useStyles from './styled/HospitalFormMakeStyles';
import { SectionBar } from '../../../componentsUI/SectionBar';
import { ScrollableContainer } from '../../Common/ScrollableContainer';
import { DeleteHospitalDialog } from './modal/DeleteHospitalDialog';
import { networkErrorParse } from '../../../utils/ErrorGraphQLUtils';

export const HospitalEdit = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const [file, setFile] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [serverError, setServerError] = useState(false);
  const { loading, data } = useQuery(GET_HOSPITAL, {
    variables: { uuid: hospitalUuid },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [updateHospital, { loading: updating }] = useMutation(UPDATE_HOSPITAL, {
    onCompleted({ updateHospital: newData }) {
      dispatch({
        type: 'CHANGE_HOSPITAL',
        current: newData,
      });
      themeContext.setPrimaryColor(data.hospital.mainColor);
      toast(`${t('settings.updated.successfully')}...`, { className: 'toast-info' });
    },
    refetchQueries: [{ query: GET_HOSPITALS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    try {
      const updateHospitalInput = {
        uuid: data.hospital.uuid,
        name: values.name,
        streetAddress: values.streetAddress,
        postalCode: values.postalCode,
        city: values.city,
        country: values.country.toUpperCase(),
        mainColor: values.mainColor,
      };
      if (file) updateHospitalInput.logo = file;
      await updateHospital({ variables: { updateHospitalInput } });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (Object.keys(formikErrors).length === 0 && formikErrors.constructor === Object) {
        setServerError(networkErrorParse(errors));
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteModal(false);
  };

  const hospital = data && data.hospital;

  const buttons = [
    { name: 'hospital.delete', icon: DeleteForeverTwoTone, I: 'delete', data: hospital, handleClick: () => setShowDeleteModal(true), disabled: false },
  ];

  return (
    <>
      <Navbar className={classes.subheader}>
        <SectionBar title="hospital.settings" items={buttons} />
        <DeleteHospitalDialog open={showDeleteModal} onClose={handleCloseDelete} hospital={hospital} />
      </Navbar>

      <Box className={classes.container}>
        <ScrollableContainer padding="1.25em 0 0">
          <Paper elevation={2} className={classes.wrapper}>
            {loading || isEmpty(data) ? (
              <Loading />
            ) : (
              <HospitalForm
                hospital={data.hospital}
                submitLabel={t('update')}
                handleSubmit={handleSubmit}
                serverError={serverError}
                setFile={setFile}
                submitting={updating}
              />
            )}
          </Paper>
        </ScrollableContainer>
      </Box>
    </>
  );
};
