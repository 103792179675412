import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { navigate } from 'gatsby';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { useDispatch } from 'react-redux';
import Loading from '../../../Common/Loading';
import { Column } from '../../../Common/styled/Groups';
import { GreyLabel } from '../../../Common/styled/GreyLabel';
import { ExtraBold } from '../../../Common/styled/Text';
import { Margin } from '../../../Common/styled/Margins';
import { GET_HOSPITALS } from '../../../../graphql/queries';
import { DELETE_HOSPITAL } from '../../../../graphql/mutations';
import { AlertUI } from '../../../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      width: '100%',
      maxWidth: 600,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export const DeleteHospitalDialog = ({ open, onClose, hospital = {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const hospitalUuid = hospital.uuid;
  const [serverError, setServerError] = useState(false);

  const { data } = useQuery(GET_HOSPITALS);
  const nextHospital = data && data.hospitals && data.hospitals.edges.length && data.hospitals.edges[0].node;
  const [deleteHospital, { loading: updating }] = useMutation(DELETE_HOSPITAL, {
    onCompleted() {
      dispatch({
        type: 'CHANGE_HOSPITAL',
        current: nextHospital,
      });
      onClose();
      toast('Hospital removed.', { className: 'toast-success' });
      navigate('/');
    },
    refetchQueries: [{ query: GET_HOSPITALS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async () => {
    setServerError(false);
    try {
      await deleteHospital({ variables: { hospitalUuid } });
    } catch (errors) {
      if (errors.graphQLErrors && errors.graphQLErrors.length) setServerError(errors.graphQLErrors[0].message);
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>
        {t('hospital.confirm.delete')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('hospital.delete.confirmation.message')}
        </DialogContentText>
        <Margin mx-0 mt-3>
          <Column>
            <ul>
              <li>
                <GreyLabel>
                  {t('hospital.name')}
                  :&nbsp;
                </GreyLabel>
                <ExtraBold>{`${hospital.name}`}</ExtraBold>
              </li>
              <li>
                <GreyLabel>
                  ID:&nbsp;
                </GreyLabel>
                <ExtraBold>{`${hospital.uuid}`}</ExtraBold>
              </li>
            </ul>
          </Column>
        </Margin>
        <p>
          {t('hospital.delete.confirmation.message.details1')}
          <br />
          {t('hospital.delete.confirmation.message.details2')}
        </p>
        <h3>{t('hospital.delete.confirmation.message.confirm')}</h3>
        {serverError ? <AlertUI severity="error" title="Error">{t(serverError)}</AlertUI> : null}
      </DialogContent>
      {updating && <Loading />}
      <DialogActions>
        <Button variant="outlined" className={classes.button} color="primary" onClick={onClose} disabled={updating}>{t('cancel')}</Button>
        <Button variant="contained" className={classes.button} color="primary" onClick={handleSubmit} disabled={updating}>{t('delete')}</Button>
      </DialogActions>
    </Dialog>
  );
};
