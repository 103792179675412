import React from 'react';

import Layout from '../../../components/MenuLayout';
import { HospitalEdit } from '../../../components/Hospital/Edit/HospitalEdit';

export default () => (
  <Layout type="settings">
    <HospitalEdit />
  </Layout>
);
